import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import formValidations from "../../utils/formValidations";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Alert, Container, Snackbar, Typography } from "@mui/material";
import { CargoFollowType, NewCargoBodyType } from "./cargosAPI";
import { createCargo, selectCreateCargosStatus } from "./cargosSlice";
import CargoForm from "./CargoForm/CargoForm";
import {
  isAerealCargo,
  isCustomsCargo,
  isGroundCargo,
  isMaritimeCargo,
} from "../../app/constants";
import { getCities } from "../cities/citiesSlice";
import { CARGOS } from "../../routes";
import { initialCargo } from "./constants/cargoContants";
import { UserType } from "../users/usersAPI";
import { CityType } from "../cities/citiesAPI";

export default function CreateCargo() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [currentCargo, setCurrentCargo] = useState({ ...initialCargo });

  const status = useAppSelector(selectCreateCargosStatus);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  const [formError, setFormError] = useState({
    type: false,
    incoterm: false,
    client: false,
    agent: false,
    departureCity: false,
    destinationCity: false,
    originCity: false,
    shipper: false,
    finance: false,
  });

  const [maritimeFormError, setMaritimeFormError] = useState({
    etd: false,
    eta: false,
    terminal: false,
    booking: false,
    mbl: false,
    hbl: false,
    connections: false,
    containers: false,
  });

  const [aerealFormError, setAerealFormError] = useState({
    etd: false,
    eta: false,
    booking: false,
    hawb: false,
    mawb: false,
    flightNumber: false,
  });

  const [groundFormError, setGroundFormError] = useState({
    crt: false,
    dua: false,
  });

  const [customsFormError, setCustomsFormError] = useState({
    knowledgeNumber: false,
  });

  const handleOnChange = (label: string, value: string | any | null) => {
    setCurrentCargo((data) => ({ ...data, [label]: value }));
    setFormError((errors) => ({ ...errors, [label]: false }));
  };

  const handleSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    updatedCargo: NewCargoBodyType | null = null,
  ) => {
    event.preventDefault();

    let cargo = currentCargo;
    if (updatedCargo) {
      cargo = updatedCargo;
    }

    if (cargo.commodity?.quantities) {
      if (cargo.commodity.quantities.grossWeight) {
        cargo.commodity.quantities.grossWeight = Number(
          cargo.commodity.quantities.grossWeight,
        );
      }
      if (cargo.commodity.quantities.weight) {
        cargo.commodity.quantities.weight = Number(
          cargo.commodity.quantities.weight,
        );
      }
      if (cargo.commodity.quantities.package) {
        cargo.commodity.quantities.package = Number(
          cargo.commodity.quantities.package,
        );
      }
      if (cargo.commodity.quantities.height) {
        cargo.commodity.quantities.height = Number(
          cargo.commodity.quantities.height,
        );
      }
    }

    if (cargo.finance) {
      cargo.finance = cargo.finance.map((finance) => ({
        ...finance,
        amount: finance.amount ? Number(finance.amount) : undefined,
        hasIVA: finance.hasIVA === true,
      }));
    }

    const validIncoterm = cargo.incoterm?.length ? true : false;

    if (!validIncoterm)
      setFormError((errors) => ({ ...errors, incoterm: true }));

    const validType = cargo.type?.length ? true : false;

    if (!validType) {
      setFormError((errors) => ({ ...errors, type: true }));
    }

    let validClient = true;
    if (cargo.client?.length && formValidations.validateMongoId(cargo.client))
      validClient = true;
    else validClient = false;

    if (!validClient) setFormError((errors) => ({ ...errors, client: true }));

    let validAgent = true;
    if (!isCustomsCargo(cargo.type)) {
      if (cargo.agent?.length && formValidations.validateMongoId(cargo.agent))
        validAgent = true;
      else validAgent = false;
    }

    if (!validAgent) setFormError((errors) => ({ ...errors, agent: true }));

    let validDepartureCity = true;
    if (
      isCustomsCargo(cargo.type) ||
      (typeof cargo.cities?.departure === "string" &&
        cargo.cities?.departure?.length &&
        formValidations.validateMongoId(cargo.cities?.departure))
    )
      validDepartureCity = true;
    else validDepartureCity = false;

    if (!validDepartureCity)
      setFormError((errors) => ({ ...errors, departureCity: true }));

    let validDestinationCity = true;
    if (
      isCustomsCargo(cargo.type) ||
      (typeof cargo.cities?.destination === "string" &&
        cargo.cities?.destination &&
        formValidations.validateMongoId(cargo.cities?.destination))
    )
      validDestinationCity = true;
    else validDestinationCity = false;

    if (!validDestinationCity)
      setFormError((errors) => ({ ...errors, destinationCity: true }));

    const validShipper = cargo.shipper?.length ? true : false;

    if (!validShipper) {
      setFormError((errors) => ({ ...errors, shipper: true }));
    }

    let validMaritime = true;

    if (isMaritimeCargo(cargo.type)) {
      if (!cargo.maritime) {
        //TODO: handle error;
        return false;
      }

      if (cargo.maritime.freeDelayDays)
        cargo.maritime.freeDelayDays = Number(cargo.maritime.freeDelayDays);

      if (cargo.maritime.connections?.length) {
        cargo.maritime.connections = cargo.maritime.connections.map(
          (connection) => ({
            ...connection,
            id: undefined,
            city: (connection.city as CityType)._id,
            eta: connection.eta
              ? new Date(connection.eta.toString()).toISOString()
              : undefined,
            etd: connection.etd
              ? new Date(connection.etd.toString()).toISOString()
              : undefined,
          }),
        );
      }

      //TODO: validate maritime cargo inputs

      const validConnections =
        cargo.maritime?.connections?.every((conn) => !!conn.city) ?? false;

      if (!validConnections)
        setMaritimeFormError((errors) => ({ ...errors, connections: true }));

      const validContainers =
        cargo.maritime?.containers.length > 0 &&
        (cargo.maritime?.containers?.every((container) => !!container.type) ??
          false);

      if (!validContainers)
        setMaritimeFormError((errors) => ({ ...errors, containers: true }));

      validMaritime = validConnections && validContainers;
    }

    let validAereal = true;

    if (isAerealCargo(cargo.type)) {
      if (!cargo.aereal) {
        //TODO: handle error;
        return false;
      }
    }

    let validGround = true;

    if (isGroundCargo(cargo.type)) {
      if (!cargo.ground) {
        //TODO: handle error;
        return false;
      }
    }

    let validCustoms = true;

    if (isCustomsCargo(cargo.type)) {
      if (!cargo.customs) {
        //TODO: handle error;
        return false;
      }
    }

    const validFinance =
      cargo.finance?.every(
        (finance) => !!finance.type && !!finance.amount && !!finance.concept,
      ) ?? false;

    if (!validFinance) setFormError((errors) => ({ ...errors, finance: true }));

    if (
      validType &&
      validIncoterm &&
      validClient &&
      validAgent &&
      validShipper &&
      validDepartureCity &&
      validDestinationCity &&
      validFinance &&
      validMaritime &&
      validAereal &&
      validGround &&
      validCustoms
    ) {
      if (cargo.follows) {
        cargo.follows = cargo.follows.map(
          (value) =>
            ({
              ...value,
              user: (value.user as UserType)?._id,
            } as CargoFollowType),
        );
      }
      dispatch(createCargo(cargo)).then((value) => {
        if (value.meta.requestStatus === "fulfilled") {
          navigate(CARGOS);
          setOpenSnackbar(true);
        } else if (value.meta.requestStatus === "rejected") {
          setOpenErrorSnackbar(true);
        }
      });
    }
  };

  const handleCloseSnackbar = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleCloseErrorSnackbar = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorSnackbar(false);
  };

  const getCitiesPage = () =>
    dispatch(
      getCities({
        page: 1,
        pageSize: 99999,
      }),
    );

  useEffect(() => {
    getCitiesPage();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Carga creada
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseErrorSnackbar}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          Ocurrió un error al crear la carga
        </Alert>
      </Snackbar>
      <Container
        component="main"
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 8,
          marginBottom: 20,
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ alignSelf: "center" }}
        >
          Crear Carga
        </Typography>
        <CargoForm
          status={status}
          currentCargo={currentCargo}
          formError={formError}
          handleOnChange={handleOnChange}
          handleSubmit={handleSubmit}
          setCurrentCargo={setCurrentCargo}
          onCancel={() => navigate(-1)}
          maritimeFormError={maritimeFormError}
          aereoFormError={aerealFormError}
          groundFormError={groundFormError}
          customsFormError={customsFormError}
        />
      </Container>
    </>
  );
}
