import { DateTime } from "luxon";

import API from "../../app/apiConfig";
import {
  CargoMaritimeTerminal,
  CargoStatus,
  CargoTypeEnum,
  ContainerType,
  FinanceTypeEnum,
  MblType,
  IncoTerm,
} from "../../app/constants";
import { transformObjectToQueryString } from "../../utils/transformObjectToQueryString";
import { AgentType } from "../agents/agentsAPI";
import { CityType } from "../cities/citiesAPI";
import { ClientType } from "../clients/clientsAPI";
import { UserType } from "../users/usersAPI";

const base = "/cargos";

export enum CurrencyCode {
  USD = "USD",
  UYU = "UYU",
  EUR = "EUR",
  GBP = "GBP",
}

export type GetCargosFilters = {
  search?: string;
  page?: number;
  pageSize?: number;
  status?: string[];
  type?: string;
  seller?: string;
  client?: string;
  agent?: string;
  minEta?: string;
  maxEta?: string;
  sortField?: string;
  sortValue?: string;
};

export type CargoCommodityQuantitiesType = {
  package?: number;
  weight?: number;
  grossWeight?: number;
  height?: number;
};

export type CargoCommodityType = {
  description?: string;
  value?: string;
  quantities?: CargoCommodityQuantitiesType;
  currency?: CurrencyCode;
};

export type ExtendedCargoFinanceType = CargoFinanceType & {
  id: number;
};

export type CargoFinanceType = {
  type?: FinanceTypeEnum;
  amount?: number;
  hasIVA: boolean;
  concept: string;
  currency?: CurrencyCode;
};

export type CargoFollowType = {
  user?: UserType | string;
  date: string | DateTime | Date;
  comment: string;
  isNew?: boolean; //! value added to NOT send user when create a new follow comment. API is the one in charge to do that
};

export type CargoCitiesType = {
  origin?: CityType | string;
  departure?: CityType | string;
  departureDetail?: string;
  destination?: CityType | string;
  destinationDetail?: string;
  crossingCustoms?: string;
};

export type CargoMaritimeMblType = {
  type: MblType;
  number: string;
};

export type ExtendedCargoConnectionType = CargoConnectionType & {
  id: number;
};

export type CargoConnectionType = {
  city?: CityType | string;
  eta?: Date | DateTime | string;
  etd?: Date | DateTime | string;
};

export type ExtendedCargoMaritimeContainerType = CargoMaritimeContainerType & {
  id: number;
};

export type CargoMaritimeContainerType = {
  type?: ContainerType;
  seal: string;
  number: string;
};

export type CargoMaritimeType = {
  status: CargoStatus;
  booking: string;
  mbl: CargoMaritimeMblType;
  hbl: string;
  eta?: Date | DateTime | string;
  etd?: Date | DateTime | string;
  connections: CargoConnectionType[];
  currentConnections?: ExtendedCargoConnectionType[];
  containers: CargoMaritimeContainerType[];
  currentContainers?: ExtendedCargoMaritimeContainerType[];
  terminal?: CargoMaritimeTerminal;
  returningTerminal?: CargoMaritimeTerminal;
  deconsolidationDeposit?: string;
  freeDelayDays?: number;
  insurance: false;
  stockNumber?: string;
  stockReference?: string;
  vessel?: string;
};

export type CargoAerealType = {
  status: CargoStatus;
  booking: string;
  hawb: string;
  mawb: string;
  flightNumber: string;
  eta?: Date | DateTime | string;
  etd?: Date | DateTime | string;
};

export type CargoGroundType = {
  status: CargoStatus;
  crt: string;
  dua: string;
  departureDate?: Date | DateTime | string;
  crossDate?: Date | DateTime | string;
  deliveryDate?: Date | DateTime | string;
};

export type CargoCustomsType = {
  knowledgeNumber: string;
  duaNumber?: string;
  dispatcher?: string;
  status: CargoStatus;
  eta?: Date | DateTime | string;
};

export type CargoType = {
  _id: string;
  number?: number;
  client?: ClientType;
  type: CargoTypeEnum;
  incoterm: IncoTerm;
  commodity: CargoCommodityType;
  consignee: string;
  shipper: string;
  responsible: string;
  agent?: AgentType;
  agentReference?: string;
  finance: CargoFinanceType[];
  cities: CargoCitiesType;
  maritime?: CargoMaritimeType;
  aereal?: CargoAerealType;
  ground?: CargoGroundType;
  customs?: CargoCustomsType;
  ranking?: number;
  follows?: CargoFollowType[];
  eta?: Date | string; //only in lists
  createdAt: string;
  updatedAt: string;
  cargoRef?: string;
  status?: string;
};

export type NewCargoBodyType = {
  _id?: string;
  number?: number;
  client?: string;
  type: CargoTypeEnum | null;
  incoterm: IncoTerm | null;
  commodity?: CargoCommodityType;
  consignee?: string;
  shipper?: string;
  responsible?: string;
  agent?: string;
  agentReference?: string;
  finance?: CargoFinanceType[];
  currentFinance?: ExtendedCargoFinanceType[];
  cities: CargoCitiesType;
  maritime?: CargoMaritimeType;
  aereal?: CargoAerealType;
  ground?: CargoGroundType;
  customs?: CargoCustomsType;
  ranking?: number;
  eta?: Date | string; //only in lists
  follows?: CargoFollowType[];
  createdAt?: string;
  updateAt?: string;
  cargoRef?: string;
};

function getCargos(filters: GetCargosFilters) {
  const filterString = transformObjectToQueryString(filters);

  return API.get(`${base}?${filterString}`);
}

function getCargoDataToExport(filters: GetCargosFilters) {
  const filterString = transformObjectToQueryString(filters);

  return API.get(`${base}/export?${filterString}`);
}

function getCargoByIdOrNumber(id: string) {
  return API.get(`${base}/${id}`);
}

function createCargo(body: NewCargoBodyType) {
  return API.post(base, body);
}

function updateCargo(body: NewCargoBodyType) {
  return API.put(`${base}/${body._id}`, body);
}

const cargosAPI = {
  getCargos,
  getCargoByIdOrNumber,
  createCargo,
  updateCargo,
  getCargoDataToExport,
};
export default cargosAPI;
